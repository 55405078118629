import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Button,
	Box,
	InputAdornment,
	Tabs,
	Tab,
	withStyles,
	FormControlLabel,
	Checkbox,
	Divider,
	Grid,
	MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import BodyText from './BodyText';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
// import { loginDispatch } from '../reducers/UsersReducer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SuccessModal from '../components/reusableComponents/SuccessModal';
import ErrorModal from '../components/reusableComponents/ErrorModal';
import {
	agentSignupDispatch,
	travellerSignupDispatch,
} from '../reducers/UserReducer';
// import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '12.5px 14px',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},

		backgroundColor: 'white',
		marginBottom: 20,
		width: '100%',
		marginTop: 10,
		borderRadius: 15,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	InputPropsStyle: {
		backgroundColor: '#F6F8FB',
		borderRadius: 8,
		fontSize: 18,
	},
	inputPropsStyle: {
		// padding: 14,
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#F6F8FB',
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
}));
// const country = ['INDIA', 'SRILANKA'];
const GreenCheckbox = withStyles({
	root: {
		color: '#39c9bb',
		'&$checked': {
			color: '#39c9bb',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const country = ['Jharkhand', 'Kolkata'];
const title = ['Mr', 'Mrs'];
const EaseBuzzBusForm = ({ pay }) => {
	const classes = useStyles();
	// const theme = useTheme();
	const [tabvalue, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [check, setCheck] = useState(false);
	const { fareQuoteEtrav, fareQuote, fareQuoteReturn, onewayflightdata } =
		useSelector((state) => state.home);
	const { farequotetripjack } = useSelector((state) => state.tripjack);

	const dispatch = useDispatch();
	const history = useHistory();
	const handleCheck = (event) => {
		setCheck(event.target.checked);
	};
	const location = useLocation();
	const { travellermodal } = useSelector((state) => state.ui);
	const [currentStep, SetCurrentStep] = useState('Step 1');
	console.log('Location', location);

	const handleFormSubmit = (data, actions) => {
		console.log(data);
		// history.push(`/pg/techprocess-o.php/${data.cardname}/${Amount}/${data.mobile}/${data.email}/${orderID}`)
	};

	return (
		<>
			<form
				action='https://www.newinv.musafirbazar.com/pg/easebuzz/request.php'
				method='POST'>
				<Grid container alignItems='center'>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='txnid'>OrderID</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{' '}
						<input
							name='mrctTxtID'
							id='txnid'
							value={location.state.orderId}
							className={classes.selectField}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center'>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='amount'>Amount</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='amount'
							id='amount'
							value={
								pay === 'offline'
									? `${
											location.state.selectSeat &&
											location.state?.selectSeat
												?.map((item) => parseInt(item.totalFare))
												.reduce((prev, next) => prev + next)
									  }.00`
									: `${
											Number(
												location.state.selectSeat &&
													location.state?.selectSeat
														?.map((item) => parseInt(item.totalFare))
														.reduce((prev, next) => prev + next)
											) +
											Number(
												Math.round(
													Number(
														Number(
															location.state.selectSeat &&
																location.state?.selectSeat
																	?.map((item) => parseInt(item.totalFare))
																	.reduce((prev, next) => prev + next)
														) * 2.5
													) / 100
												)
											)
									  }.00`
							}
							className={classes.selectField}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center'>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{/* <label for='surl'>returnURL</label> */}
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{' '}
						<input
							hidden
							name='returnURL'
							id='surl'
							value='http://trimurtitourandtravels.in/paymentVerify'
							className={classes.selectField}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center'>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{/* <label for='furl'>failedURL</label> */}
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{' '}
						<input
							hidden
							name='failedURL'
							id='furl'
							value='http://trimurtitourandtravels.in/failed'
							className={classes.selectField}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center'>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='phone'>Mobile</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='phone'
							id='phone'
							value={location.state.data.phone}
							className={classes.selectField}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center'>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='email'>Email</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='email'
							id='email'
							value={location.state.data.email}
							className={classes.selectField}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center'>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='firstname'>Name</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='firstname'
							id='firstname'
							value={location.state.data.Passenger[0].name}
							className={classes.selectField}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center'>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='productinfo'>Productinfo</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='productinfo'
							id='productinfo'
							value='Bus Booking'
							className={classes.selectField}
						/>
					</Grid>
				</Grid>

				<div>
					<button
						className={classes.createButton}
						style={{ cursor: 'pointer' }}>
						Proceed to Payment{' '}
					</button>
				</div>
			</form>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default EaseBuzzBusForm;
