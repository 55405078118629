import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './sass/main.scss';
import HomeLoader from './components/reusableComponents/HomeLoader';
ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<HomeLoader />}>
			<App />
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);
reportWebVitals();
