import axios from 'axios';
import { axiosInstance } from '../Axios';
import {
	loader,
	storeData,
	toggleEasebuzzModal,
	togglePGModal,
	toggleSessionExpiredModal,
} from './UiReducer';
const { createSlice } = require('@reduxjs/toolkit');

const BusReducer = createSlice({
	name: 'Bus',
	initialState: {
		success: false,
		error: null,
		loadingbus: false,
		loadingbus2: false,
		layoutloading: false,
		buscity: sessionStorage.getItem('buscity')
			? JSON.parse(sessionStorage.getItem('buscity'))
			: null,
		busSearch: null,
		busDes: null,
		seatLayout: null,
		busBlock: sessionStorage.getItem('busBlock')
			? JSON.parse(sessionStorage.getItem('busBlock'))
			: null,
		busBooking: null,
		busBookingDetails: null,
		addBusSearch: null,
		addBusBooking: null,
		debitbuspayment: null,
	},
	reducers: {
		cityBusRequest(state, action) {
			state.loadingbus = true;
		},
		cityBusSuccess(state, action) {
			state.loadingbus = false;
			state.buscity = action.payload;
			storeData('buscity', action.payload);
			state.error = null;
		},
		cityBusFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		addBusSearchRequest(state, action) {
			state.loadingbus = true;
		},
		addBusSearchSuccess(state, action) {
			state.loadingbus = false;
			state.addBusSearch = action.payload;
			state.error = null;
		},
		addBusSearchFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		debitBusPaymentRequest(state, action) {
			state.loadingbus = true;
		},
		debitBusPaymentSuccess(state, action) {
			state.loadingbus = false;
			state.debitBusPayment = action.payload;
			state.error = null;
		},
		debitBusPaymentFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		addBusBookingRequest(state, action) {
			state.loadingbus2 = true;
		},
		addBusBookingSuccess(state, action) {
			state.loadingbus2 = false;
			state.addBusBooking = action.payload;
			state.error = null;
		},
		addBusBookingFail(state, action) {
			state.loadingbus2 = false;
			state.error = action.payload;
		},
		busSearchRequest(state, action) {
			state.loadingbus = true;
		},
		busSearchSuccess(state, action) {
			state.loadingbus = false;
			state.busSearch = action.payload;
			state.error = null;
		},
		busSearchFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		busDesRequest(state, action) {
			state.loadingbus2 = true;
		},
		busDesSuccess(state, action) {
			state.loadingbus2 = false;
			state.busDes = action.payload;
			state.error = null;
		},
		busDesFail(state, action) {
			state.loadingbus2 = false;
			state.error = action.payload;
		},
		seatLayoutRequest(state, action) {
			state.layoutloading = true;
		},
		seatLayoutSuccess(state, action) {
			state.layoutloading = false;
			state.seatLayout = action.payload;
			state.error = null;
		},
		seatLayoutFail(state, action) {
			state.layoutloading = false;
			state.error = action.payload;
		},
		busBlockRequest(state, action) {
			state.loadingbus = true;
		},
		busBlockSuccess(state, action) {
			state.loadingbus = false;
			state.busBlock = action.payload;
			storeData('busBlock', action.payload);
			state.error = null;
		},
		busBlockFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		busBookingRequest(state, action) {
			state.loadingbus = true;
		},
		busBookingSuccess(state, action) {
			state.loadingbus = false;
			state.busBooking = action.payload;
			state.error = null;
		},
		busBookingFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		busBookingDetailsRequest(state, action) {
			state.loadingbus = true;
		},
		busBookingDetailsSuccess(state, action) {
			state.loadingbus = false;
			state.busBookingDetails = action.payload;
			state.error = null;
		},
		busBookingDetailsFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
		debitbuspaymentRequest(state, action) {
			state.loadingbus = true;
		},
		debitbuspaymentSuccess(state, action) {
			state.loadingbus = false;
			state.debitbuspayment = action.payload;
			state.error = null;
		},
		debitbuspaymentFail(state, action) {
			state.loadingbus = false;
			state.error = action.payload;
		},
	},
});
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjO7hqe3PG9trimti==',
	},
};
const { actions } = BusReducer;

export const {
	cityBusRequest,
	cityBusSuccess,
	cityBusFail,
	addBusSearchRequest,
	addBusSearchSuccess,
	addBusSearchFail,
	addBusBookingRequest,
	addBusBookingSuccess,
	addBusBookingFail,
	debitBusPaymentRequest,
	debitBusPaymentSuccess,
	debitBusPaymentFail,
	busSearchRequest,
	busSearchSuccess,
	busSearchFail,
	busDesRequest,
	busDesSuccess,
	busDesFail,
	seatLayoutRequest,
	seatLayoutSuccess,
	seatLayoutFail,
	busBlockRequest,
	busBlockSuccess,
	busBlockFail,
	busBookingRequest,
	busBookingSuccess,
	busBookingFail,
	busBookingDetailsRequest,
	busBookingDetailsSuccess,
	busBookingDetailsFail,
	debitbuspaymentRequest,
	debitbuspaymentSuccess,
	debitbuspaymentFail,
} = actions;

export const fetchBusCityDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(cityBusRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busallsources',
			bodyData,
			config
		);

		console.log(data);
		dispatch(cityBusSuccess(data));
	} catch (error) {
		dispatch(
			cityBusFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addBusSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(addBusSearchRequest());
		const { data } = await axiosInstance.post(
			'Bus/addsearchbus',
			bodyData,
			config
		);

		console.log(data);
		dispatch(addBusSearchSuccess(data));
		if (data.status === 200) {
			dispatch(fetchBusSearchDispatch(bodyData));
		}
	} catch (error) {
		dispatch(
			addBusSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addBusBookingDispatch =
	(bodyData, formData, pay) => async (dispatch) => {
		try {
			dispatch(addBusBookingRequest());
			const { data } = await axiosInstance.post(
				'bus/addbookingbus',
				bodyData,
				config
			);

			console.log(data);
			dispatch(addBusBookingSuccess(data));
			if (data.status === 200) {
				dispatch(busBlockDispatch(formData, pay));
			}
		} catch (error) {
			dispatch(
				addBusBookingFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const debitBusPaymentDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(debitBusPaymentRequest());
		const { data } = await axiosInstance.post(
			'bus/debitbuspayment',
			bodyData,
			config
		);

		console.log(data);
		dispatch(debitBusPaymentSuccess(data));
		// if (data.status === 200) {
		// 	dispatch(fetchBusSearchDispatch(bodyData));
		// }
	} catch (error) {
		dispatch(
			debitBusPaymentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchBusCityDesDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(busDesRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busdestinationforsource',
			bodyData,
			config
		);

		console.log(data);
		dispatch(busDesSuccess(data));
	} catch (error) {
		dispatch(
			busDesFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const fetchBusSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(busSearchRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/bussearch',
			bodyData,
			config
		);

		console.log(data);
		dispatch(busSearchSuccess(data));
	} catch (error) {
		dispatch(
			busSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchSeatLayoutDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(seatLayoutRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busseatlayout',
			bodyData,
			config
		);

		console.log(data);
		dispatch(seatLayoutSuccess(data));
	} catch (error) {
		dispatch(
			seatLayoutFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const busBlockDispatch = (bodyData, pay) => async (dispatch) => {
	try {
		dispatch(busBlockRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busblock',
			bodyData,
			config
		);

		console.log(data);
		dispatch(busBlockSuccess(data));
		if (data.status === 200) {
			if (pay === 'offline') {
				// console.log('first');
				dispatch(togglePGModal(true));
			} else {
				dispatch(toggleEasebuzzModal(true));
			}
		} else {
			dispatch(toggleSessionExpiredModal(true));
		}
	} catch (error) {
		dispatch(
			busBlockFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const busBookingDispatch =
	(bodyData, history, location) => async (dispatch) => {
		try {
			dispatch(busBookingRequest());
			const { data } = await axiosInstance.post(
				'BusSeat/busbooking',
				bodyData,
				config
			);

			console.log(data);
			dispatch(busBookingSuccess(data));
			if (data.status === 200) {
				let form = {
					ticketId: data.result,
				};
				dispatch(busBookingDetailsDispatch(form, history, location));
			}
		} catch (error) {
			dispatch(
				busBookingFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const busBookingDetailsDispatch =
	(bodyData, history, location) => async (dispatch) => {
		try {
			dispatch(busBookingDetailsRequest());
			const { data } = await axiosInstance.post(
				'BusSeat/busbookingdetails',
				bodyData,
				config
			);

			console.log(data);
			dispatch(busBookingDetailsSuccess(data));
			if (data.status === 200) {
				history.push({
					pathname: '/busticket',
					state: {
						bookingDetail: data,
						detail: location,
					},
				});
			}
		} catch (error) {
			dispatch(
				busBookingDetailsFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const debitbuspaymentDispatch =
	(bodyData, blockKey, history, location) => async (dispatch) => {
		try {
			dispatch(debitbuspaymentRequest());
			const { data } = await axiosInstance.post(
				'bus/debitbuspayment',
				bodyData,
				config
			);

			console.log(data);
			dispatch(debitbuspaymentSuccess(data));
			if (data.status === 200) {
				let formdata = {
					blockKey: blockKey,
				};
				dispatch(busBookingDispatch(formdata, history, location));
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			dispatch(
				debitbuspaymentFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export default BusReducer;
